<template>
    <button
        type="button"
        class="inline-flex items-center rounded-md font-medium leading-4 focus:outline-none"
        :class="[tailleBouton, getClasseCouleurBouton(modele as ModeleBoutonEnum)]"
        :disabled="desactive"
        :data-element="props.dataElement"
    >
        <slot></slot>
    </button>
</template>

<script setup lang="ts">
import { computed, withDefaults } from 'vue';
import { ModeleBoutonEnum } from '@/composants/generiques/bouton/modele-bouton.enum';

interface Props {
    taille?: 'petit' | 'moyen' | 'grand' | 'custom';
    desactive?: boolean;
    modele: string;
    dataElement?: string;
}

const props = withDefaults(defineProps<Props>(), {
    taille: 'grand',
    desactive: false,
    dataElement: undefined,
});

const tailleBouton = computed(() => {
    switch (props.taille) {
        case 'petit':
            return 'px-2.5 py-1.5 text-xs h-7';
        case 'moyen':
            return 'px-3 py-2 text-sm h-9';
        case 'grand':
            return 'px-4 py-2 text-base h-10';
        default:
            return '';
    }
});

function getClasseCouleurBouton(modeleBouton: ModeleBoutonEnum): string {
    const classe: string[] = [];
    classe.push(getClasseCouleurBaseBouton(modeleBouton));
    if (props.desactive) {
        classe.push('opacity-30 cursor-not-allowed');
    } else {
        classe.push(getClasseBoutonActif(modeleBouton));
        classe.push('cursor-pointer');
    }
    return classe.join(' ');
}

function getClasseCouleurBaseBouton(modeleBouton: ModeleBoutonEnum): string {
    switch (modeleBouton) {
        case ModeleBoutonEnum.PRIMAIRE:
            return 'bg-primaire-500 text-white';
        case ModeleBoutonEnum.SECONDAIRE:
            return 'bg-secondaire-100 text-gray-700';
        case ModeleBoutonEnum.TERTIAIRE:
            return 'text-gray-500';
        case ModeleBoutonEnum.SUCCES:
            return 'text-success-dark';
        case ModeleBoutonEnum.ERREUR:
            return 'text-error-dark';
        case ModeleBoutonEnum.ERREURBG:
            return 'bg-red-100 text-red-700';
        case ModeleBoutonEnum.LIEN:
            return 'text-brand-default';
        default:
            return '';
    }
}

function getClasseBoutonActif(modeleBouton: ModeleBoutonEnum): string {
    switch (modeleBouton) {
        case ModeleBoutonEnum.PRIMAIRE:
            return 'hover:bg-primaire-600';
        case ModeleBoutonEnum.SECONDAIRE:
            return 'hover:bg-secondaire-300';
        case ModeleBoutonEnum.TERTIAIRE:
            return 'hover:bg-gray-100 hover:text-gray-700';
        case ModeleBoutonEnum.SUCCES:
            return 'hover:bg-success-light';
        case ModeleBoutonEnum.ERREUR:
            return 'hover:bg-error-light';
        case ModeleBoutonEnum.LIEN:
            return 'hover:underline';
        default:
            return '';
    }
}
</script>
